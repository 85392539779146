.invoice-summary {
  width: 290px;
  padding: 0 20px;
}

.invoice-summary .total-amount {
  font-size: 1.125rem;
}

.invoice-summary .order-amount, .invoice-summary .shipping-charge, .invoice-summary .discount-amount, .invoice-summary .tax-amount {
  font-size: 0.875rem;
  line-height: 26px;
}

.invoice-summary .total-amount {
  margin-bottom: 12px;
}

.invoice-summary .payment-amount {
  margin-top: 24px;
}

.invoice-summary .section-value {
  font-weight: 600;
}

.invoice-summary .pricing-details {
  color: #1557bf;
}

.invoice-summary .pricing-img-wrapper.normal-img::after {
  content: '';
  background-image: url('../icons/dropdown-icon.svg');
  display: inline-block;
  width: 10px;
  position: absolute;
  height: 4px;
  top: 12px;
  margin-left: 6px;
}

.invoice-summary .pricing-img-wrapper.rotate180deg::after {
  content: '';
  background-image: url('../icons/dropdown-icon.svg');
  display: inline-block;
  width: 10px;
  position: absolute;
  height: 4px;
  top: 12px;
  margin-left: 6px;
  transform: rotate(180deg);
}

.invoice-summary .pricing-img-wrapper {
  position: relative;
  cursor: pointer;
}