.invoice-summary {
  width: 310px !important;
  padding: 0 20px;
}

.invoice-summary .total-amount {
  font-size: 1.125rem;
}

.invoice-summary .order-amount,
.invoice-summary .shipping-charge,
.invoice-summary .discount-amount,
.invoice-summary .tax-amount {
  font-size: 0.75rem;
  line-height: 26px;
  text-overflow: ellipsis;
}

.invoice-summary .service-fee {
  font-size: 0.875rem !important;
}

.invoice-summary .total-amount {
  margin-bottom: 12px;
}

.invoice-summary .payment-amount {
  margin-top: 24px;
}

.invoice-summary .section-value {
  font-weight: 600;
}

.invoice-summary .payment-pending-amount {
  color: #f88c8c;
}

.invoice-summary .payment-refund-amount {
  color: #1557bf;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2rem;
  margin-left: 1rem;
}

.nowrap-amount {
  white-space: nowrap;
}
