.appcard-form h3 {
  grid-column: 1/3;
}

.appcard-form .w-100 {
  width: 100%;
}

.appcard-form .card-content {
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.appcard-form .card {
  margin: 0 auto;
  min-height: 10.75rem;
  grid-column: 1/3;
}

.appcard-form .rc-time-picker {
  display: flex;
}

.appcard-form .appFormButtons .primary {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}

.appcard-form .preview-button {
  margin: 0 auto;
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
  grid-column: 1/3;
  border-radius: 0.25rem;
}

.appcard-form .preview-button:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

@media screen and (max-width: 683px) {
  .appcard-form .appFormButtons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (min-width: 683px) {
  .appcard-form .grid-2-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    padding: 0 var(--s3);
  }

  .appcard-form .validFrom {
    grid-column: 1/2;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .appcard-form .validTo {
    grid-column: 2/3;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .appcard-form .appFormButtons {
    grid-column: 2/3;
    justify-self: flex-end;
    margin-top: 1rem;
  }

  .appcard-form .appFormButtons .primary {
    margin-left: var(--s3);
  }
}
