.order-meta-data {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

@media screen and (max-width: 768px) {
  .order-meta-data {
    grid-template-columns: 1fr;
  }
}