.order-payments-form .table .table-cell:last-child {
  text-align: left;
}

.order-payments-form .form-action {
  margin-top: 40px;
}

.order-payments-form {
  max-width: 100%;
}

.order-payments-form .table-cell.amount {
  max-width: 120px;
}

.order-payments-form .table-cell.amount input {
  text-align: right;
}

.order-payments-form .table-cell.transactionid {
  max-width: 180px;
}
