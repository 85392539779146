@use 'styles/colors' as *;

.sale-order-details h1.heading {
  font-weight: 300;
  color: $fp-grey;
}

.sale-order-details h1.heading .reference-number {
  color: $fp-black;
}

.sale-order-details .section-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 1.25rem 0;
  line-height: 1.75rem;
}

.sale-order-details .section-title h3, .sale-order-details .section-title h5 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.sale-order-details .section-title.title {
  margin: 0.625rem 0;
}
.sale-order-details .section-title h5 {
  font-size: 0.875rem;
  margin-top: 0.625rem;
}

.sale-order-details .order-status-pending {
  color: $fp-red-4;
}

.sale-order-details .order-status-completed {
  color: $fp-green-2;
}

.sale-order-details .order-status-cancelled {
  color: $fp-red-3;
}

.sale-order-details .user-details {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.sale-order-details .address-details {
  width: 16.25rem;
}

.sale-order-details .customer-name {
  color: #1557bf;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
}

.sale-order-details {
  .order-placement-info-item {
    min-width: 17.5rem;
    padding: 0.75rem 0;
    border-top: $fp-white-2 dashed 1px;
  }
  .order-placement-info-item:last-child {
    border-bottom: $fp-white-2 dashed 1px;
  }
}

.sale-order-details .order-placement-info-icon {
  margin-right: 0.625rem;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
}

.sale-order-details .icon-clock {
  background-image: url('../../../../icons/clock-icon.svg');
}

.sale-order-details .icon-mobile {
  background-image: url('../../../../icons/mobile-icon.svg');
}

.sale-order-details .creation-time,
.sale-order-details .device-name {
  color: $fp-black;
  font-weight: 600;
}

.sale-order-details .retry-message {
  text-align: center;
  cursor: pointer;
}

.sale-order-details .button {
  min-width: 5rem;
  padding: 0 1rem;
}

.sale-order-details .address-details .customer-heading {
  margin-bottom: 0.625rem;
}
.sale-order-details .payment-details .payment-card {
  margin-right: 0.625rem;
}
.sale-order-details .payment-details .payment-card-number .image-sm img {
  width: 1.875rem;
  height: 1.875rem;
}
