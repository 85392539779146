.order-items-view {
  position: relative;
}

.order-items-view .table-cell {
  padding-top: 10px;
  padding-bottom: 10px;
}

.order-items-view .item-quantity {
  width: 90px;
}

.order-items-view .item-amount {
  font-weight: 600;
  color: #80959d;
  font-size: 0.75rem;
}

.order-items-view .table-footer {
  margin-top: 20px;
}

.order-items-view .product-search {
  flex: 1;
  max-width: 470px;
}

.order-items-view .section-title {
  margin-bottom: 14px;
  line-height: 44px;
}

.order-items-view .remove-button-container {
  position: relative;
}

.order-items-view .searchable-product-details {
  margin-left: 10px;
}

.order-items-view .brand-name {
  font-weight: bold;
}

.order-items-view .total-amount {
  font-size: 0.875rem;
}

.order-items-view .remove-button {
  position: absolute;
  top: 50%;
  right: -20px; /* To offset the table padding */
  transform: translate(50%, -50%);
  border-radius: 50%;
  background-color: #f88c8c;
  display: inline-block;
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}

.order-items-view .remove-button:hover {
  opacity: 1;
}

.order-items-view .table {
  position: relative;
}

.order-items-view .empty-table-message {
  text-align: center;
  color: #80959d;
  font-size: 0.75rem;
  margin: 16px 0;
}

.order-items-view .message-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: background 0.2s ease-in-out;
}

.order-items-view .message-overlay.hidden {
  background-color: transparent;
}

.order-items-view .error-message {
  background-color: rgba(0, 0, 0, 0.6);
}

.order-items-view .retry-message {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  transition: color 0.2s ease-in-out;
}

.order-items-view .retry-message:hover {
  color: white;
}

.order-items-view .product-name {
  color: #588292;
}

.order-items-view .product-name .brand-name {
  color: #2b3238;
}

.order-items-view .item-name .item-comment {
  font-size: 12px;
  line-height: 2;
}

.order-items-view .table .table-row:last-child .table-cell {
  white-space: nowrap;
}

.order-items-view .table .table-cell .item-table-mobile-view {
  display: none;
}
.order-items-view .table .table-row .mobile-view-header {
  display: none;
}

.order-items-view .table .table-row .table-cell.item-name .item-link {
  display: flex;
}

.order-items-view
  .table
  .table-row.child-row
  .table-cell.item-name
  .item-link
  .image {
  margin-right: 0.5rem;
}

.order-items-view
  .table
  .table-row
  .table-cell.item-name
  .item-link
  .text-muted {
  margin: auto 0;
}

.order-items-view .table .table-row.child-row {
  opacity: 0.5;
}

.order-items-view .table .table-row .table-cell.item-image {
  position: relative;
}

.order-items-view
  .table
  .table-row
  .table-cell.item-image
  .substitution-icon-img {
  position: absolute;
  right: 0.5625rem;
  bottom: 0.3rem;
}

@media screen and (max-width: 550px) {
  .order-items-view .table-footer {
    flex-flow: row wrap;
    flex-direction: column-reverse;
    margin-top: -20px;
  }
  .order-items-view .invoice-summary {
    margin-top: 24px;
    padding: 0;
    width: 100%;
  }
  .order-items-view .section-title {
    flex-flow: row wrap;
  }
  .order-items-view .product-search {
    flex-basis: 100%;
    margin-top: 12px;
  }
  .order-items-view .table .table-header {
    display: none;
  }
  .order-items-view .table .table-row {
    display: grid;
  }
  .order-items-view .table .table-row {
    background-color: white;
    border: 1px solid #eaefef;
    margin-bottom: 20px;
    position: relative;
  }
  .order-items-view .table .table-row .table-cell {
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    text-align: left;
    align-items: center;
  }

  .order-items-view .table .table-cell.item-image,
  .order-items-view .table .table-cell.item-name {
    grid-row-start: 1;
    grid-column-start: 1;
  }
  .order-items-view .table .table-cell.item-name {
    margin-left: 65px;
    padding: 0px 15px 10px 15px;
    align-items: center;
  }

  .order-items-view .table .table-cell.item-name .product-name,
  .order-items-view .table .table-cell.item-amount {
    font-size: 14px;
    font-weight: 600;
  }
  .order-details .order-summary-wrapper .order-items-view {
    margin-top: 20px;
  }
  .order-items-view .table .table-row .mobile-view-header {
    color: #80959d;
    font-size: 12px;
    display: block;
  }
  .order-items-view .table .table-row .table-cell.item-image {
    border-bottom: 1px solid #eaefef;
    margin-bottom: 5px;
    position: relative;
  }
  .order-items-view .table .table-row .table-cell.item-amount {
    border-top: 1px solid #eaefef;
    background-color: #fbfcfc;
    margin-top: 5px;
  }

  .order-items-view .table .table-row .table-cell.item-amount,
  .order-items-view .table .table-row .table-cell.item-image {
    padding: 10px 15px;
  }

  .order-items-view
    .table
    .table-row
    .table-cell.item-amount
    .mobile-view-header {
    font-size: 14px;
  }

  .order-items-view .table .table-row .remove-button-container {
    position: unset;
    color: #2b3238;
  }

  .order-items-view .table .table-row .remove-button-container .remove-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .order-items-view .table .flex-around.table-footer .order-edit-actions {
    margin-top: 20px;
  }

  .order-items-view
    .table
    .flex-around.table-footer
    .order-edit-actions
    button {
    min-width: 48%;
  }

  .order-items-view .table .table-cell.item-original input[type='number'] {
    height: 30px;
    width: 80px;
  }

  .order-items-view .table .table-row:last-child {
    display: none;
  }
  .order-details .order-summary-wrapper .order-items-view .order-edit-actions {
    margin-top: 15px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .order-details
    .order-summary-wrapper
    .order-items-view
    .order-edit-actions
    .button {
    min-width: 48%;
    margin: 0px;
  }
  .order-items-view .table .table-cell .text-muted,
  .order-items-view .table .table-cell.client-item-id.text-muted {
    color: #2b3238;
  }

  .order-items-view .invoice-summary .total-amount,
  .order-items-view .invoice-summary .payment-amount {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 480px) {
  .payment-details .table .table-header {
    display: none;
  }
  .payment-details .table .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    border: 1px solid #eaefef;
    background: #ffffff;
    margin: 15px 0;
  }
  .payment-details .table .table-row .table-cell {
    border: none;
    padding: 4px 15px;
  }
  .payment-details .table .table-row .table-cell div {
    font-size: 13px;
    font-weight: 600;
  }
  .payment-details .table .table-row .payment-mode,
  .payment-details .table .table-row .payment-card-number,
  .payment-details .table .table-row .payment-status,
  .payment-details .table .table-row .payment-amount {
    border-top: 1px solid #eaefef;
    grid-row-start: 2;
  }
  .payment-details .table .table-row .payment-transaction-id {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: left;
  }
  .payment-details .table .table-row .table-cell .text-muted {
    margin-bottom: -10px;
    font-size: 11px;
  }
  .payment-details .table .table-row .payment-transaction-id .transactionId {
    line-height: 20px;
    display: block;
    margin-top: 4px;
    margin-bottom: 2px;
  }
  .payment-details .table .table-row .payment-status {
    text-align: right;
    font-size: 13px;
  }
  .payment-details .table .table-row .payment-amount {
    text-align: center;
  }
  .payment-details .table .table-row .payment-card-number {
    text-align: left;
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .payment-details .table .table-row .payment-amount {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .order-details .order-metadata-details {
    margin: 0px;
  }

  .order-items-view .table .table-cell .item-table-mobile-view {
    display: block;
    font-size: 12px;
    color: #80959d;
  }
}

@media screen and (max-width: 440px) {
  .payment-details .table .table-cell:first-child {
    padding-left: 15px;
  }
}

.client-item-id .mkp-label {
  margin-left: 2px;
  font-weight: bold;
}
