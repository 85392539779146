.order-summary-wrapper .order-placement-details {
  width: 350px;
}

.order-summary-wrapper .order-placement-details .section-pre-order {
  display: flex;
  justify-content: space-between;
}

.order-summary-wrapper .order-placement-details .pre-order {
  margin-top: 5px;
}

.order-summary-wrapper .order-placement-details .pre-order img {
  margin-right: 8px;
  margin-top: -5px;
}

.user-details .flex {
  display: flex;
  align-items: center;
}
.user-details .flex .button-container {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
.user-details .error {
  font-size: 11px;
  color: red;
}

.order-placement-info-item .red-note {
  color: #FB424B;
}
