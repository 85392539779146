.pickers-today .progress-bar {
    width: 280px;
    display: inline-block;
    border: 1px solid #ebebeb;
    position: relative;
    margin-top: 24px;
}

.pickers-today .in-progress {
    display: inline-block;
    position: absolute;
    border: 1px solidrgba(21, 87, 191, 0.7);
    top: 0;
    left: -1px;
    transform: translate3d(0,-50%,0);
}

.pickers-today .pickers-progress-time {
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.pickers-today .time-pointer{
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    top: 0;
    transform: translate3d(0,-50%,0);
}

.pickers-today .time-pointer:hover .tooltip-text{
    visibility: visible;
}

.pickers-today .tooltip-text {
    visibility: hidden;
    width: 100px;
    background-color: #fbfcfc;
    color: #2b3238;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    font-size: 12px;
}