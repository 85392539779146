.edit-packed-order .items-view .table input {
    width: 5rem;
}

.edit-packed-order h1.heading {
    font-weight: 300;
    color: #80959d;
}
.edit-packed-order h1.heading .reference-number {
    color: #2b3238;
  }

.edit-packed-order form {
    max-width: unset;
}

.edit-packed-order .foot-buttons {
    margin: 1rem 0;
    position: relative;
    display: flex;
    flex-direction:row-reverse;
}
.edit-packed-order .foot-buttons input.primary {
    margin-right: .75rem;
}



.edit-packed-order .loader-wrapper {
    position: absolute;
    top: 40%;
    margin: 0 auto;
    width: 100%;
    z-index: 10;
}


@media only screen and (min-width: 33.75rem) {
    .edit-packed-order input#packages-crates {
        max-width: 12rem;
        margin-left: auto;
    }
  }