.create-order .address-card, .create-order .pickuplocation-card {
    padding: 20px;
    display: inline-block;
    background-color: #fbfcfc;
    margin-bottom: 10px;
    letter-spacing: -0.1px;
    font-size: 0.75rem;
    color: #80959d;
    cursor: pointer;
    margin-right: 10px;
}

.create-order .address-card {
    min-width: 270px;
}

.create-order .pickuplocation-card {
    width: 200px;
}

.create-order .address-card.selected, .create-order .pickuplocation-card.selected {
    background-color: #2b3238;
    color: #ffffff
}

.create-order .address-card:hover, .create-order .pickuplocation-card:hover {
    color: #ffffff;
    background-color: #5f6c78
}

.create-order .customer-details-card {
    padding: 20px;
    max-width: 500px;
    border-radius: 2px;
    background-color: #fbfcfc;
    margin-bottom: 50px;
}

.create-order .subheading {
    font-weight: bold;
    letter-spacing: -0.3px;
    margin-bottom: 15px;
    font-size: 1.125rem;
}

.create-order .customer-details-card {
    font-size: 0.875rem;
}

.create-order .customer-details-card .customer-details-name, .create-order .address-card .pincode {
    font-weight: 600;
}

.create-order .customer-details-card .detail {
    letter-spacing: -0.1px;
    font-size: 0.75rem;
    color: #80959d;
}

.create-order .user-image {
    height: 32px;
    width: 32px;
    margin-right: 15px;
    border-radius: 50%;
}

.create-order .header-container {
    justify-content: space-between;
}

.create-order .address-details-wrapper.no-address {
    height: 40px;
}

.create-order .new-address-button.no-address {
    left: 20px;
}

@media screen and (max-width: 480px) {
    .create-order .table .item-name small{
        font-size: 14px;
        font-weight: 600;
    }
    .create-order .table .table-cell {
        padding: 15px;
    }
    .create-order .table .table-row .item-mrp .text-muted, .create-order .table .table-row .item-amount .text-muted, .create-order .table .table-row .item-discount .text-muted, .create-order .table .table-row .table-cell.item-amount strong{
        font-size: 13px;
        font-weight: 600;
        color: #2b3238;
    }
    .create-order .table .table-row .item-mrp .item-header, .create-order .table .table-row .item-amount .item-header, .create-order .table .table-row .item-discount .item-header{
        font-size: 11px;
        font-weight: normal;
        color: #2b3238;
    }
    .create-order .table .table-row .table-cell.item-mrp , .create-order .table .table-row .table-cell.item-amount , .create-order .table .table-row .table-cell.item-discount {
        border-top:  1px solid #eaefef; 
        background-color: #fbfcfc;
        padding: 15px;
    }
    .create-order .table .table-row .table-cell.item-mrp {
        text-align: left;
    }
    .create-order .table .table-row .table-cell.item-discount {
        text-align: center;
    }
    .create-order .table .table-row .table-cell.item-amount .remove-button {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .create-order .table .table-row .table-cell.item-name {
        margin-left: -40px;
        margin-right: -40px;    
    }
    .create-order .table .table-row .table-cell.item-quantity .flex{
        margin-top: 25px;
        flex-direction: row-reverse;
    }
    .order-add-form .products-list .table {
        margin-bottom: 15px;
    }

}