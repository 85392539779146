.order-add-form {
  max-width: inherit;
  margin-top: -20px;
}

.order-add-form .product-search {
  flex: 1;
  max-width: 500px;
  /* margin-top: -8px;   Removed cause of margin issue while displaying error state with address form such as if user forgets to select slot*/
}

.order-add-form .button-wrapper {
  padding: 20px 0;
  text-align: right;
}

.order-add-form .button-wrapper input {
  margin-left: 10px;
}

.order-add-form .customer-name {
  max-width: 420px;
}

.order-add-form .customer-details-wrapper {
  margin-top: 40px;
}

.order-add-form .cash-tendered,
.order-add-form .balance-amount-holder {
  padding: 5px 20px;
  align-items: center;
}

.order-add-form .balance-amount {
  font-weight: 600;
}

.order-add-form .cash-tendered-text {
  flex-grow: 1;
}

.order-add-form .cash-tendered .cash-tendered-input {
  width: 100px;
  text-align: right;
}

.order-add-form .details-wrapper {
  flex-grow: 1;
}

.order-add-form .customer-name {
  margin-right: 20px;
  flex-grow: 1;
}

.order-add-form .Searchable .select-field-dropdown {
  max-height: 160px;
  height: inherit;
}

.order-add-form .order-type .Radio .radio-label::before,
.order-add-form .payment-mode .Radio .radio-label::before {
  margin-left: 20px;
}

.order-add-form .order-type .Radio .radio-label,
.order-add-form .payment-mode .Radio .radio-label {
  position: relative;
  justify-content: flex-start;
}

.order-add-form .new-address-button {
  font-size: 0.875rem;
  letter-spacing: -0.3px;
  cursor: pointer;
  right: 0;
  margin-bottom: 10px;
  position: absolute;
}

.order-add-form .order-type-delivery {
  flex-direction: column;
}

.order-add-form .order-type,
.order-add-form .payment-mode {
  max-width: 500px;
  margin-bottom: 0;
}

.order-add-form .payment-mode .radio-label:last-child::after {
  content: '';
  background-image: url('../icons/cash-payment-icon.svg');
  position: absolute;
  height: 30px;
  width: 30px;
  right: 10px;
  top: 12px;
}

.order-add-form .payment-mode .radio-label:first-child::after {
  content: '';
  background-image: url('../icons/online-payment-icon.svg');
  position: absolute;
  height: 30px;
  width: 30px;
  right: 10px;
  top: 12px;
}

.order-add-form .order-type .radio-label:last-child::after {
  content: '';
  background-image: url('../icons/delivery-icon.svg');
  position: absolute;
  height: 30px;
  width: 30px;
  right: 10px;
  top: 12px;
}

.order-add-form .order-type .radio-label:first-child::after {
  content: '';
  background-image: url('../icons/pickup-icon.svg');
  position: absolute;
  height: 30px;
  width: 30px;
  right: 10px;
  top: 12px;
}

.order-add-form .address-details-wrapper {
  max-width: 500px;
  border: 1px solid var(--border-color);
  background-color: #fbfcfc;
  padding: 10px;
}

.order-add-form .address-details-wrapper .input-error-message {
  margin-bottom: 3.9rem;
}

.order-add-form .products-list .table {
  margin-bottom: 40px;
}

.order-add-form .add-icon,
.order-add-form .subtract-icon {
  cursor: pointer;
}

.order-add-form .quantity {
  margin: 0 10px 0 10px;
}

.order-add-form .remove-button {
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: -3px;
}

.order-add-form .remove-button-container {
  display: flex;
  justify-content: flex-end;
}

.order-add-form .searchable-product-details {
  margin-left: 10px;
}

.order-add-form .product-searchable {
  margin-bottom: 22px;
  margin-top: 30px;
}

.order-add-form .searchable-product-details .product-name {
  font-weight: 400;
  font-size: 12px;
  color: #2b3238;
}

.order-add-form .searchable-product-details .product-name .bold {
  font-weight: bold;
}

.order-add-form .price-holder {
  margin-top: 5px;
}

.order-add-form .price-holder .price {
  font-weight: 400;
  color: #645f5f;
}

.order-add-form .Searchable .select-field-dropdown .select-option:hover {
  background-color: #f1f5f2;
  color: inherit;
}

.order-add-form .new-address-button {
  color: #1557bf;
  font-weight: 600;
}

.order-add-form .new-address-button img {
  margin-right: 6px;
}

.order-add-form .address-card-list,
.order-add-form .order-pickup-location {
  margin-top: 10px;
}

.order-add-form .order-pickup-location .radio-label {
  min-width: 200px;
}

.order-add-form .address-card-list .radio-label {
  min-width: 250px;
  padding: 15px;
  min-height: 120px;
  background-color: #fbfcfc;
  max-width: 33%;
}

.order-add-form .date-list .radio-label {
  max-width: 17%;
}
.order-add-form .slot-list .radio-label {
  max-width: 20%;
}
.order-add-form .order-pickup-location .radio-label {
  background-color: #fbfcfc;
}

.order-add-form .date-details {
  margin-top: 40px;
}

.order-add-form .address-card-list .Radio {
  overflow-x: auto;
  padding-left: 1px;
}

.order-add-form .address-card-list .Radio .radio-label:not(.selected)::before,
.order-add-form
  .order-pickup-location
  .Radio
  .radio-label:not(.selected)::before {
  background-color: #ffffff;
}

.order-add-form .address-card-list .Radio .radio-label.selected,
.order-add-form .order-pickup-location .Radio .radio-label.selected {
  border-color: var(--border-color);
  font-weight: normal;
}

.order-add-form .address-card-list .radio-label-text {
  max-width: 200px;
}

.order-add-form .date-list {
  margin-bottom: 0;
  margin-top: -10px;
}

.order-add-form .slot-list {
  margin-top: 10px;
}

.order-add-form .address-card-list {
  margin-bottom: 10px;
}

.order-add-form .preffered-slot-error {
  font-size: small;
  color: red;
}

@media screen and (max-width: 768px) {
  .order-add-form .address-card-list .radio-label {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .order-add-form .products-list {
    overflow-x: auto;
  }
  .order-add-form .payment-options {
    flex-wrap: wrap;
  }
  .order-add-form .product-search {
    margin-top: 0px;
  }
  .order-add-form .customer-details-wrapper .flex {
    flex-direction: column;
  }
  .order-add-form .customer-name {
    margin-right: 0px;
  }
  .order-add-form .order-type {
    margin-bottom: 10px;
  }
  .order-add-form .button-wrapper .button,
  .order-add-form .button-wrapper input {
    min-width: 48%;
  }
  .order-add-form .payment-options .invoice-summary,
  .order-add-form .cash-tendered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .order-add-form .button-wrapper .button-white {
    margin-left: 0px;
  }
  .order-add-form .cash-tendered.flex-around,
  .order-add-form .invoice-summary .flex-around {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .order-add-form .invoice-summary {
    width: 100%;
  }
  .order-add-form .invoice-summary .flex-around {
    margin-top: 8px;
  }
  .order-add-form .cash-tendered .cash-tendered-input {
    width: 100%;
  }
  .order-add-form .flex-around.total-amount div:last-child,
  .order-add-form .flex-around.order-amount div:last-child,
  .order-add-form .flex-around.shipping-charge div:last-child,
  .order-add-form .flex-around.discount-amount div:last-child {
    text-align: right;
  }
  .order-add-form .actions-with-meta-data .field.field-checkbox {
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .order-add-form
    .actions-with-meta-data
    .field.field-checkbox
    input[type='checkbox']
    + label {
    margin-bottom: 0px;
  }
  .order-add-form .invoice-summary .total-amount {
    margin-bottom: 8px;
  }
  .order-add-form .customer-details-wrapper .field.customer-name {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .order-add-form .date-list .radio-label,
  .order-add-form .slot-list .radio-label {
    max-width: 50%;
  }

  .order-add-form .table .table-header {
    display: none;
  }

  .order-add-form .table .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    border: 1px solid #eaefef;
    background: #ffffff;
    margin: 15px 0;
  }

  .order-add-form .table .table-row .table-cell {
    border: none;
  }
}
