.phone-form .phone-wrapper input[type='submit'] {
    margin-top: 26px;
    margin-left: 10px;
    height: 44px;
}

.phone-form .react-phone-number-input button {
    min-width: 70px;
}

.phone-form .react-phone-number-input ul {
    z-index: 2;
}

.phone-form {
    max-width: 100%;
}

.phone-form .phone-number {
    margin-bottom: 10px;
    width: 100%;
    max-width: 404px;
}