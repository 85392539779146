.file-dispute-form form {
  max-width: 100%;
}

.file-dispute-form .return-qty {
  margin-left: 20px;
  padding: 10px 0;
  font-size: 0.75rem;
}

.file-dispute-form .form-action {
  margin-top: 40px;
}

.file-dispute-form .table .table-cell:last-child {
  text-align: left;
}