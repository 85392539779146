.order-metadata-details .form-action {
  margin-bottom:  20px;
}

.order-metadata-details {
  margin-bottom: 20px;
  margin-top: 20px;
}

.order-metadata-details label {
  font-weight: 600;
}