.card {
  width: 8.488rem;
  height: 12.938rem;
  border: 1px solid #eaeaea;
  padding: 0.5rem;
}

.card .card-image-wrapper {
  margin-bottom: 0.5rem;
  border: 1px solid #eaeaea;
}

.card .card-image {
  width: 100%;
  max-height: 7.188rem;
}

.card p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  font-style: normal;
  overflow: hidden;
}

.appcard-listingpage .column-Scheduled,
.column-Live {
  color: green;
  font-weight: bold;
}

.appcard-listingpage .column-Expired,
.column-Unpublished {
  color: red;
  font-weight: bold;
}

.appcard-listingpage .column-name span:hover {
  cursor: pointer;
  color: hsl(0, 0%, 60%);
}
